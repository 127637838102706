<template>
  <div class="text-center">
    <v-scroll-y-transition>
      <v-dialog v-model="dialog" max-width="350px" persistent>
        <template v-slot:activator="{ on }">
          <v-btn
            v-show="!hidden"
            elevation="2"
            dark
            color="grey darken-4"
            v-on="on"
          >
            Iniciar Sesión
          </v-btn>
        </template>
        <v-form ref="form">
          <v-card>
            <v-card-text>
              <v-container>
                <v-text-field
                  v-model="email"
                  label="Email"
                  :rules="[rules.required, rules.email]"
                  autocomplete="off"
                  :disabled="submited"
                ></v-text-field>
                <v-text-field
                  v-model="password"
                  label="Password"
                  :type="show3 ? 'text' : 'password'"
                  :rules="[rules.required]"
                  autocomplete="off"
                  :disabled="submited"
                  :append-icon="show3 ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="show3 = !show3"
                ></v-text-field>
              </v-container>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-col class="text-right">
                <v-btn
                  color="grey darken-4"
                  dark
                  @click="submit"
                  :disabled="submited"
                >
                  Iniciar
                </v-btn>
              </v-col>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-dialog>
    </v-scroll-y-transition>
    <v-snackbar v-model="snackbar">
      {{ snackbar_text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="pink" text v-bind="attrs" @click="snackbar = false">
          CERRAR
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
import { firebase } from "@/firebase";
export default {
  data() {
    return {
      dialog: false,
      hidden: true,
      disabled: false,
      submited: false,
      email: "",
      password: "",
      show3: false,
      snackbar: false,
      snackbar_text: null,
      rules: {
        required: (value) => !!value || "Requerido.",
        email: (value) =>
          /.+@CITYRED.CL$/.test(value.toUpperCase()) || "Email invalido",
      },
    };
  },
  mounted() {
    setTimeout(() => (this.hidden = false), 750);
  },
  methods: {
    async submit() {
      if (!this.$refs.form.validate()) return;
      this.disabled = true;
      firebase
        .auth()
        .signInWithEmailAndPassword(
          this.email.toUpperCase(),
          this.password.toUpperCase()
        )
        .then((authResult) => {
          authResult.user.getIdTokenResult().then((tokenResult) => {
            if (tokenResult.claims.allow_access) {
              let admin = false;
              if (tokenResult.claims.allow_access_admin) admin = true;
              this.$store.commit("setUser", {
                uid: authResult.user.uid,
                name: authResult.user.email,
                admin: admin,
              });
              this.$router.push({ name: "home" });
            } else this.$router.push({ name: "noaccess" });
          });
        })
        .catch((error) => {
          this.disabled = false;
          this.snackbar = true;
          this.snackbar_text = error.message;
        });
    },
  },
};
</script>
<style lang="scss" scoped></style>
